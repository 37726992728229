<template>
  <div style="touch-action: pan-y;">
  <div style="position: absolute;z-index: 0;width: 100%;">
    <img src="../../assets/images/wallet-bg.png" alt="" style="width: 100%;lightgray 50%" />
  </div>
  <div style="position: absolute;margin-top: 2%;margin-left: 3%;width: 100%;z-index: 1;">
    <img src="../../assets/images/roaclogo.png" alt="" />
    <!-- <img src="../../assets/images/msg.png" alt="" style="width: 5%;margin-left: 65%;" @click="goTo('/user/message')" /> -->
  </div>
  <div class="my">
    <div>
      <img src="../../assets/images/wallet-bgg.png" style="width: 95%;margin-left: 1%;position: absolute;;" alt="" />
      <span style="font-size: 0.6rem;font-weight: bold;position: absolute;color: black;margin-left:20%;margin-top: 5%;">資產服務</span>
    <div class="wallet_box" style="margin-top: 18%;">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/usdt.png" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #000;">USDT</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;color: #000;">{{ userInfo.money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink1">
            <p style="font-size: 16px;color: #B791FA;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
      <div class="das" style="margin-top: 20px;">
        <p class="btns dis" style="background-color: #B791FA;border-radius: 20px;color: black;" @click="goRecharge(1)">
          充幣
        </p>
        <p class="btns dis" style="border-color: #B791FA;border-radius: 20px;color: black;" @click="Withdrawal(1)">
          提幣
        </p>
      </div>
    </div>
    </div>
    <div class="wallet_box" style="margin-top: 60%;">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/xg/LOGO.svg" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #000;">Roc</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;color: #000;">{{ userInfo.personal_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink2">
            <p style="font-size: 16px;color: #B791FA;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
        <van-row style="margin-top: 5%;" v-if="userInfo.is_deposite === 2">
          <van-col span="10" class="das"><span style="font-size: 0.4rem;color: #000;width: 300px;">凍結金額</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 0.4rem;color: #000;margin-left: 5%;">{{ userInfo.wait_score }}</span></van-col>
        </van-row>
      </div>
      <div class="das" style="margin-top: 20px;">
        <p class="btns dis" style="background-color: #B791FA;border-radius: 20px;color: black;" @click="goRecharge(2)">
          充幣
        </p>
        <p class="btns dis" style="border-color: #B791FA;border-radius: 20px;color: black;" @click="Withdrawal(2)">
          提幣
        </p>
        <p v-if="userInfo.is_deposite === 2"  style="color: #B791FA;border-radius: 20px;font-size: 0.3rem;margin-left: 5%;" @click="huazhuan()">
          劃轉
        </p>
      </div>
    </div>
    <div style="margin-left: 40%;font-size: 20px;margin-top: 10%;color: aliceblue;">鎖倉錢包</div>
    <img src="../../assets/images/s-wallet.png" style="width: 95%;margin-left: 1%;position: absolute;margin-top: 90%;" alt="" />
    <div class="wallet_box" style="margin-top: 110%;">
      <div>
        <van-row>
          <van-col span="4" class="das"><img src="../../assets/images/xg/LOGO.svg" style="width: 40px;height: 40px;" alt=""></van-col>
          <van-col span="5" class="das"><span style="font-size: 20px;color: #000;">Roc</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;color: #000;">{{ userInfo.shouyi_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink3">
            <p style="font-size: 16px;color: #B791FA;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
     <!-- <div class="das" style="margin-top: 20px;">
        <p class="btns dis" @click="goRecharge(2)">
          充幣
        </p>
        <p class="btns dis" @click="Withdrawal(2)">
          提幣
        </p>
      </div> -->
    </div>
   <!-- <div class="wallet_box" style="margin-top: 10px;">
      <div>
        <van-row>
          <van-col span="10" class="das"><span style="font-size: 20px;color: #3E74EE;">推廣總收益</span></van-col>
          <van-col span="10" class="des"><span style="font-size: 20px;">{{ userInfo.shouyi_money }}</span></van-col>
          <van-col span="4" class="dis" @click="routerlink3">
            <p style="font-size: 16px;color: #3E74EE;font-weight: 700;">
              賬簿<van-icon name="arrow" />
            </p>
          </van-col>
        </van-row>
      </div>
    </div> -->
    <div class="banner df fdc jcsb bsbb">

      <!-- <div class="bord bsbb df fdc" style="color: #fff">
        <div class="df aic jcsb">
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">USDT</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.money }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">ROC</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.personal_money }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">收益钱包</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.shouyi_money }}</p>
          </div>
        </div>
        <div class="df aic jcsb mt40">
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">总消费券</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.consume_ticket }}</p>
          </div>
          <div class="df aic fdc">
            <p class="fz24 fw7" @click="routerlink">总分红权</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.fenhong_power }}</p>
          </div>
          <div class="df fdc aic">
            <p class="fz24 fw7" @click="routerlink">累计收益</p>
            <p class="fz24 fw7" style="margin-top: 15px;text-align: center;">{{ userInfo.green_score_reward }}</p>
          </div>
        </div>
        <div style="font-size: 14px" class="mb30 fw7" @click="routerlink">{{ userInfo.money }}</div>
        <div style="font-size: 14px">
          分享额度 : {{ userInfo.consume_score }}
        </div>
       <div class="btns mt40 fw7 df">
          <div class="charge df aic jcc mr30" @click="goRecharge">
            <van-button class="charge" @click="goRecharge">充值</van-button>
          </div>
          <div class="upto df aic jcc" @click="goUpto">
            <van-button class="upto" @click="goUpto">提现</van-button>
          </div>
        </div>
      </div> -->

    </div>


    <footer></footer>
  </div>
  </div>
</template>

<script>
import {
  getUserInfo, AssetWithdrawal,scanChain
} from "@/utils/api.js";
import { Toast } from "vant";

export default {
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {

    routerlink1() {
      this.$router.push("/user/detailed");
    },
    routerlink2() {
      this.$router.push("/user/detailedroc");
    },
    routerlink3() {
      this.$router.push("/user/detailedshouyi");
    },
    goRecharge(rechargeAmount) {
      this.$router.push({
        path: "/user/bscRecharge",
        query: {
          amount: rechargeAmount
        }
      });
    },

    Withdrawal(rechargeAmount) {
      this.$router.push({
        path: "/user/withdraw",
        query: {
          amount: rechargeAmount
        }
      });
    },
    huazhuan() {
      this.$router.push({
        path: "/user/huazhuan",

      });
    },
    // Withdrawal(id) {
    //   console.log(1);
    //   const moneys = id === 1 ? this.userInfo.money : this.userInfo.personal_money
    //   if (moneys < 100.00) {
    //     Toast({
    //       message: '余额不足100',
    //       icon: 'fail',
    //       className: 'noticeWidth'
    //     });
    //     return
    //   }
    //   AssetWithdrawal({
    //       type: id,
    //       money: moneys,
    //     }).then((res) => {
    //     console.log(res);
    //   })
    // }
  },
  mounted() {
    getUserInfo().then((res) => {
      this.userInfo = res.data.user;
    });
    // scanChain();
  },

};
</script>

<style lang="scss" scoped>
.my{
  // background-color: #181a20 ;
  // height: 100%;
  width: 100%;
  z-index: 0;
  position:absolute;
  margin-top: 48%;
}
.wallet_box {
  color: #FFFFFF;
  font-weight: bold;
  background-color: #FFFFFF;
  width: 75%;
  margin-left: 10%;
  position: absolute;
  z-index: 3;
  border: #FFFFFF;
  // background: rgb(30, 35, 41);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 40px 20px;

}

.btns {
  margin-right: 20px;
}

.btns:nth-child(1) {
  width: 190px;
  height: 62px;
  background: #3E74EE;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  font-size: 28px;
  color: white;
}

.btns:nth-child(2) {
  width: 190px;
  height: 62px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #3E74EE;
  color: #3E74EE;
  font-size: 28px;
}



footer {
  height: 100px;
}

.noticeWidth {
  width: 30% !important;
  font-size: 32px !important;
}
</style>

