<template>
  <div  class="nodepoll">
  <!-- <img src="../../assets/images/nodepollbg.png" alt="" style="position: absolute;background-size: cover;width: 100%;" /> -->
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold;font-size: 16px;line-height: normal;">節點報名</span>
            <span @click="goTo('/user/allnodepoll')"
              style="font-weight: bold;font-size: 16px;line-height: normal;margin-left: 75%;position: absolute;">節點大選</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div style="margin-left: 5%;">
      <h4 style="font-size: 0.6rem;text-align: center;">RoAc全球節點人招募</h4>
      <p style="font-size: 0.5rem;font-weight: 600;margin-top: -8%;color: #9DA4B2;text-align: center;">尊享全球節點人分紅</p>
      <h5 style="font-size: 0.5rem;">專案介紹</h5>
      <p style="font-size: 0.38rem;font-weight: 500;color: #9DA4B2;margin-top: -5%;color: #000;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RoAc，公正的道組織，是由AGE OF DAO
        FOUNDATION發起，a16z、DCG、Grayscale、Alameda
        Research等大型機構參與投資，彙聚RoAc與ETH、XRP、Polygon、Solana等當下著名公鏈科技開發者與運營人才、基於RoAc原生公鏈開發並結合當下流行的優勢科技而打造的一個更穩定更友好的Web3.0時代的基礎設施。
        RoAc旨在開闢Web3.0時代必經之道，推動全球Web3.0產業發展，打造全球第一條多協定的Web3.0公鏈。<br />
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RoAc 公鏈為解決當下Web3.0所面臨的交易處理緩慢、資產安全問題以及加密貨幣的不穩定性和金融監管缺失等問題，結合Filecion的去中心化網路存儲協定，運用最新技術Zero Knowledge
        Proof（零知識證明）打造的Web3.0基礎設施，為Web3.0的到來打下堅實的基礎。<br />
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RoAc原生公鏈主體於2022年開始開發，實驗室環境下TPS高達2000萬，RoAc是基於去中心化交易技術、分片分層技術（應用層、合約層、激勵層、共識層、網路層、數據層）同構多鏈技術，
        構造穩定安全的全球性去中心化可持續發展經濟系統。用於支持底層API介面已進入全網公測階段，測試完成啟動主網並相繼向全球區塊鏈應用開發者開放。主網已進入節點人佈置環節，
        節點人乃RoAc網路中的特殊節點，為整個RoAc網路提供存儲、貢獻算力等，同時獲得相應獎勵。<br />
        ———本輪節點人招募採用自願報名方式，創世節點、從節點將從備選名單中採用投票競選方式選出。具體報名時間另行通知，敬請即時關注站內公告。
      </p>
      <h5 style="font-size: 0.5rem;">RoAc備選節點人權益</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;margin-top: -7%;">1.參與創世節點競選資格</p>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;">2.參與從節點競選資格</p>
      <h5 style="font-size: 0.5rem;">節點報名條件</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;margin-top: -7%;">1.推動RoAc業務發展，維護RoAc品牌形象。</p>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;">2.組建RoAc社區體系，挖掘潛在優質用戶。</p>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;">3.盡心服務RoAc用戶，推進產品優化疊代。</p>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;">4.共建RoAc健康生態，致使行業良性發展。</p>
      <h5 style="font-size: 0.5rem;">備選節點入選對象與要求</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;margin-top: -7%;">1.全網RoAc用戶。</p>
      <p style="font-size: 0.4rem;font-weight: 500;padding-top: 5%;color: #000;">2.持有（質押）Roc30000枚以上者。</p>
      <p style="font-size: 0.4rem;font-weight: 500;padding-top: 5%;color: #000;">3.舉薦10名以上有效Roc用戶。</p>
      <p style="font-size: 0.4rem;font-weight: 500;padding-top: 5%;color: #000;">4.體系Roc質押量達600000枚。</p>
      <p style="font-size: 0.4rem;font-weight: 500;padding-top: 5%;color: #000;">注：參與報名者每個地址需消耗100枚Roc。</p>
      <h5 style="font-size: 0.5rem;">報名方式與路徑</h5>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;margin-top: -7%;">1.點擊下方鍵入框填寫社區昵稱提交（社區昵稱為18Bit Byte，即漢語6個字、英文18個字，不得含有特殊和標點符號）。</p>
      <p style="font-size: 0.4rem;font-weight: 500;color: #000;">2.提交後自能合約會自動抓取相關地址並執行100枚Roc消耗，請確保站內錢包足夠餘額。</p>
    </div>
    <div style="padding: 20px;padding-bottom: 20%;" class="Recharge">
      <van-cell-group inset>
        <van-field @input="handleInput" v-model="text" placeholder="請輸入社區暱稱" />
      </van-cell-group>
      <van-button v-if="userinfo === null"
        style="background-color: #AC76FA;color: #000;font-weight: bold;width: 50%;border-radius: 20px;margin-left: 26%;"
        size="large" class="butsty" @click="dialog()">立即報名</van-button>
      <van-button v-else
        style="background-color: #AC76FA;color: #000;font-weight: bold;width: 50%;border-radius: 20px;margin-left: 26%;"
        size="large" class="butsty" >已參與</van-button>
    </div>
  </div>

</template>

<script setup>
  import {
    ref,
    reactive
  } from 'vue';
  import {
    nodereg,
    getusernoderegstatus
  } from "@/utils/api.js";
  import {
    usdtabi
  } from '@/utils/abi.js'
  import Web3 from "@/utils/web3.min.js";
  import {
    useRoute, useRouter
  } from "vue-router";
  import {
    precision
  } from '@/utils/precision.js';
  import {
    Toast
  } from 'vant';
  import {
    Notify
  } from 'vant';

  const chain_type = ref()
  const show = ref(false)
  const page = ref(1)
  const page_size = ref(50)
  const detailedlist = ref([])
  const time = ref([])
  const times = ref([])
  const text = ref()
  const onClickLeft = () => history.back();
  const router = useRouter();
  const add = ref('')
  const userinfo = ref();
  const userbalance = ref();
  // 获取用户信息
  async function getuser() {
    getusernoderegstatus().then((res) => {
      userinfo.value = res.data;
      // console.log(userinfo);
    });


  }
  getuser();

  function handleInput(event) {
    // 定义允许的字符范围
    const pattern = /^[a-zA-Z0-9]*$/;
    // 检查输入值是否包含非法字符
    const isValid = pattern.test(event.target.value);
    if (!isValid) {
      // 阻止输入事件
      event.preventDefault();
    }
  }
  function goTo(to) {
        router.push(to);
      }
  function dialog() {
    Toast("敬请期待");
  }

  function showPopup() {
    show.value = true;
  }

  function closePopup() {
    show.value = false;
  }

  async function nodepoll() {

    const web3 = new Web3(window.ethereum);
    ethereum.enable()
    //获取地址
    let accounts = await web3.eth.getAccounts();
    let fromAddress = accounts[0];

    const a = await web3.eth.getGasPrice()

    //收R地址

    let toAddress = "0x83407455bAC363D0403A06afA3808dD77a599855";

    const shouqian = toAddress
    var Tx = {
      gas: 101323,
      gasPrice: a,
      from: fromAddress,
      value: 1 + '000000000000000000',
      to: shouqian,
    };
    web3.eth.sendTransaction(Tx)
      .on('receipt', function(receipt) {
        console.log("交易已确认，收据：", receipt);
        if (receipt !== null) {
          let form = {
            teamname: text.value,
          }
          nodereg(form).then((res) => {
            Toast(res.message)
            // onClickLeft()
          })
        } else {
          Toast({
            message: '链上拥堵',
            icon: 'success',
            className: 'noticeWidth'
          });
        }
      })


  }
</script>

<style lang="scss" scoped>
  .nodepoll{
     width: 100%; /* 设置div的宽度 */
    //  height: 200px; /* 设置div的高度 */
      background-image: url('../../assets/images/nodepollbg.png'); /* 背景图片的URL */
      background-size: cover; /* 背景图片覆盖整个div */
      background-position: center; /* 背景图片居中 */
      position: absolute;
  }

  input[type="radio"]:checked::after {
    background: #000;
  }

  .van-nav-bar__content {
    height: 1rem !important;
  }

  .butsty {
    height: 80px;
    font-size: 30px;
    padding: 30px;
    border-radius: 20px;

  }

  .noticeWidth {
    width: 30% !important;
    font-size: 32px !important;
  }

  ::v-deep .van-cell {
    padding: 50px !important;
  }

  ::v-deep .van-cell-group--inset {
    border-radius: 50px !important;
  }

  ::v-deep .van-nav-bar__title {
    font-weight: bold !important;
    font-size: 30px !important;
    height: 80px;
  }

  ::v-deep .van-nav-bar__content {
    padding-top: 30px;
    height: 80px;
    // margin: auto;
  }

  ::v-deep .van-nav-bar__arrow {
    font-size: 46px !important;
    color: #000;
  }

  .van-dropdown-menu__bar {
    box-shadow: none !important;
  }

  .van-nav-bar__content {
    box-shadow: none !important;
  }

  .van-hairline--bottom:after {
    border-bottom-width: 0 !important;
  }

  .van-collapse-item {
    border: 1px red solid;
  }

  .van-popup {
    // position: absolute !important;
  }

  .van-dropdown-menu__title {
    line-height: normal !important;
  }

  .van-dropdown-menu__bar {
    height: 60px !important;
  }

  .van-nav-bar__left {
    padding: 0rem 0.3rem 0rem 0.3rem !important;
  }

  .van-ellipsis {
    font-weight: bold !important;
    overflow: visible;
  }
</style>
